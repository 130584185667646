<template>
  <fieldset>
    <div class="content">
      <h1>{{ $t('page.confirmation.title') }}</h1>
      <p>{{ $t('page.confirmation.description') }}</p>
    </div>
    <component
      v-for="(step, stepIndex) in reviewSteps"
      :key="stepIndex"
      :is="`page-${step.component}`"
      @edit="setStepAndViewModeCreate(stepIndex)"
      class="page-confirmation__review"
    >
    </component>
    <b-field
      class="mt-6"
      :type="getFieldType('authorization')"
      :message="getFieldMessage('authorization')"
    >
      <b-checkbox v-model="authorization">
        {{ getFieldLabel('authorization') }}
      </b-checkbox>
    </b-field>
    <b-field
      :type="getFieldType('acknowledgefees')"
      :message="getFieldMessage('acknowledgefees')"
    >
      <b-checkbox v-model="acknowledgeFees">
        {{ getFieldLabel('acknowledgefees') }}
      </b-checkbox>
    </b-field>
    <b-message
      v-if="!hasIncorporationAccess"
      type="is-warning"
    >
      <p>{{ $t('page.confirmation.warning') }}</p>
    </b-message>
    <div
      v-if="hasIncorporationAccess"
      class="has-text-centered"
    >
      <b-button
        type="is-blue"
        icon-right="paper-plane"
        class="form-navigation__button"
        @click="submit"
        :loading="loading"
        :disabled="!authorization || !acknowledgeFees || submitDisabled"
      >
        {{ $t('page.reqConfirmation.submit') }}
      </b-button>
    </div>
  </fieldset>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import api from '@/api';
import auth from '@/mixins/auth';
import validator from '@/mixins/validator';
import { getIncorporationFormData } from '@/utils/helper';

export default {
  components: {
    PageCompanyName: () => import('@/components/page/PageCompanyName'),
    PageDirector: () => import('@/components/page/PageDirector'),
    PageFounder: () => import('@/components/page/PageFounder'),
    PageHeadOffice: () => import('@/components/page/PageHeadOffice'),
    PageOfficer: () => import('@/components/page/PageOfficer'),
    PageOptions: () => import('@/components/page/PageOptions'),
    PageOther: () => import('@/components/page/PageOther'),
    PageProfessionalOrder: () => import('@/components/page/PageProfessionalOrder'),
    PageShare: () => import('@/components/page/PageShare'),
    PageShareholder: () => import('@/components/page/PageShareholder')
  },
  name: 'PageConfirmation',
  mixins: [
    auth,
    validator()
  ],
  computed: {
    ...mapState('form', [
      'currentForm',
      'model',
      'files',
      'steps'
    ]),
    reviewSteps () {
      return this.steps.length ? this.steps.slice(0, this.steps.findIndex(step => step.component === 'confirmation')) : [];
    }
  },
  data () {
    return {
      acknowledgeFees: false,
      authorization: false,
      submitDisabled: false,
      loading: false
    };
  },
  created () {
    this.setViewModeReview();
    this.$nextTick(() => {
      window.scrollTo(0, 0);
      document.getElementsByClassName('form-view-content-navigation').forEach(el => el?.scrollTo(0, 0));
    });
  },
  methods: {
    ...mapActions('form', [
      'setStep'
    ]),
    ...mapActions('viewMode', [
      'setViewModeCreate',
      'setViewModeReview'
    ]),
    setStepAndViewModeCreate (stepIndex) {
      this.setViewModeCreate();
      this.setStep(stepIndex);
    },
    submit () {
      this.submitDisabled = true;
      this.loading = true;

      api.createIncorporationForm(this.currentForm, getIncorporationFormData(this.$i18n.locale, this.model, this.files))
        .then(() => {
          this.$buefy.dialog.alert({
            title: this.$t('page.reqConfirmation.success.title'),
            message: this.$t('page.reqConfirmation.success.message'),
            type: 'is-success',
            hasIcon: true,
            icon: 'check-circle',
            iconPack: 'far',
            ariaRole: 'alertdialog',
            ariaModal: true,
            confirmText: this.$t('page.reqConfirmation.success.confirmText'),
            onConfirm: () => this.$router.push({ name: 'professionalaccess-incorporation', query: { quit: 'force' } })
          });
        })
        .catch(() => {
          this.submitDisabled = false;
          this.$buefy.dialog.alert({
            title: this.$t('page.reqConfirmation.error.title'),
            message: this.$t('page.reqConfirmation.error.message'),
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'far',
            ariaRole: 'alertdialog',
            ariaModal: true
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    validate () {
      if (!this.authorization)
        this.addError('authorization');
      if (!this.acknowledgeFees)
        this.addError('acknowledgefees');
    }
  }
};
</script>
